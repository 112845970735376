import React from 'react';

const Pounds: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Camada_2" clipPath="url(#clip0_76_132)">
        <path
          id="Vector"
          d="M11.954 3.4349C11.954 3.27829 11.9336 2.43263 11.2695 1.74357C10.8506 1.31552 10.258 1.04407 9.61431 1.04407H4.84291C2.72797 1.04407 1.02171 2.7876 1.02171 4.94874V12.2987C1.02171 15.0341 3.18774 17.2474 5.86462 17.2474H7.26437"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
        <path
          id="Vector_2"
          d="M4.73052 1.58691V4.87561"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="bevel"
        />
        <path
          id="Vector_3"
          d="M8.2963 1.52429V4.81299"
          stroke="#303742"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="bevel"
        />
        <path
          id="Vector_4"
          d="M11.5249 14.063L12.0358 13.541V19.5651C12.0358 20.891 13.0881 21.9559 14.3755 21.9559H19.1469C21.2618 21.9559 22.9681 20.2124 22.9681 18.0512V10.7013C22.9681 7.96591 20.802 5.75256 18.1252 5.75256H13.9259L7.26437 13.4993L7.98978 14.1675C9.00128 15.0966 10.5543 15.0444 11.5147 14.063H11.5249Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M19.2695 21.4131V18.1244"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="bevel"
        />
        <path
          id="Vector_6"
          d="M15.7037 21.4757V18.187"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="bevel"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_132">
          <rect width="24" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Pounds;
